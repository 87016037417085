import { extendTheme } from '@chakra-ui/react'

const theme = {
	styles: {
		global: {
			':not(.chakra-dont-set-collapse) > .chakra-collapse': {
				overflow: 'initial !important',
			},
			html: {
				height: '100%',
			},
			body: {
				minHeight: '100%',
			},
		},
	},
	colors: {
		primary: '#f5a877',
		brown: {
			100: '#efccb5',
		},
	},
}

export default extendTheme(theme)
