import { ApolloClient, HttpLink, InMemoryCache, from, ApolloLink, makeVar } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import fetch from 'isomorphic-fetch'

// in this file we define a logic that will retry queries after they failed

// define a local cache storage for error messages
const globalErrorsLog = makeVar<ErrorInfo[]>([])
const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				globalErrorsLog: {
					read: () => globalErrorsLog(),
				},
			},
		},
	},
})

// add operationName query params to every operation
// it will do nothing, but in devtools we should see the exact query of every operation by its name
// otherwise all queries would look the same and we will not be able to tell the difference
// without looking into the body
const customFetch: typeof fetch = (uri, options) => {
	if (!options || typeof options.body !== 'string') return fetch(uri, options)
	const { operationName } = JSON.parse(options.body)
	return fetch(`${uri}?operation=${operationName}`, options)
}

// save the info about errors: the actual graphql info
// also prevent raising runtime exceptions
const errorIgnoreLink = onError(args => {
	const { graphQLErrors, networkError, operation } = args
	const errors: string[] = []
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) =>
			errors.push(`
				[GraphQL error]:
					Message: ${message},
					Location: ${locations},
					Path: ${path}
			`)
		)
	if (networkError) errors.push(`[Network error]: ${networkError}`)
	operation.setContext({ errors })
})

export interface ErrorInfo {
	operationName: string
	errors: string[]
	delay: number
	attempt: number
}

const errorLogLink = new ApolloLink((operation, forward) => {
	const context = operation.getContext()
	let errorInfo: ErrorInfo | null = null

	// read the error information we saved above
	if (context.errors && context.errors.length > 0) {
		// make a human-readable error statement and log it
		errorInfo = {
			operationName: operation.operationName,
			errors: context.errors,
			delay: context.delay,
			attempt: context.count,
		}
		console.error(errorInfo)

		// write the error to the local cache. Each error includes its operation name
		const oldLog = globalErrorsLog()
		const newLog = oldLog.concat(errorInfo)
		globalErrorsLog(newLog)
	}

	// if operation was after all successful, clean up the error logs related to that operation
	return forward(operation).map(data => {
		operation.setContext({ errors: undefined })
		const oldLog = globalErrorsLog()
		const newLog = oldLog.filter(entry => entry.operationName !== operation.operationName)
		globalErrorsLog(newLog)
		return data
	})
})

const httpLink = new HttpLink({
	fetch: customFetch,
	uri: '/api/graphql',
	credentials: 'include',
})

const link = from([errorIgnoreLink, errorLogLink, httpLink])

export default new ApolloClient({ cache, link })
