// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archived-tsx": () => import("./../../../src/pages/archived.tsx" /* webpackChunkName: "component---src-pages-archived-tsx" */),
  "component---src-pages-column-[id]-tsx": () => import("./../../../src/pages/column/[id].tsx" /* webpackChunkName: "component---src-pages-column-[id]-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lead-[id]-tsx": () => import("./../../../src/pages/lead/[id].tsx" /* webpackChunkName: "component---src-pages-lead-[id]-tsx" */),
  "component---src-pages-lead-index-tsx": () => import("./../../../src/pages/lead/index.tsx" /* webpackChunkName: "component---src-pages-lead-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-oct-tsx": () => import("./../../../src/pages/oct.tsx" /* webpackChunkName: "component---src-pages-oct-tsx" */),
  "component---src-pages-person-[id]-tsx": () => import("./../../../src/pages/person/[id].tsx" /* webpackChunkName: "component---src-pages-person-[id]-tsx" */),
  "component---src-pages-reception-tsx": () => import("./../../../src/pages/reception.tsx" /* webpackChunkName: "component---src-pages-reception-tsx" */),
  "component---src-pages-reports-tsx": () => import("./../../../src/pages/reports.tsx" /* webpackChunkName: "component---src-pages-reports-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/signUp.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-tco-tsx": () => import("./../../../src/pages/tco.tsx" /* webpackChunkName: "component---src-pages-tco-tsx" */),
  "component---src-pages-wizard-person-id-tsx": () => import("./../../../src/pages/wizard/[personId].tsx" /* webpackChunkName: "component---src-pages-wizard-person-id-tsx" */)
}

